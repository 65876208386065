import React from "react"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImage from "../components/common/FeaturedImage"
import PageIntro from "../components/PageIntro"
export default function ForelasningarPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 99 }) {
        content
        sidinfo {
          introRubrikSida
        }
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const { content, featuredImage, title, sidinfo } = data.wpPage
  return (
    <Layout>
      <SEO title="Pratar" />
      <PageIntro text={sidinfo?.introRubrikSida || title} />

      <div className="single-page-inner">
        <div className="small-container">
          {/* <PageHeader text={title} /> */}
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {featuredImage && <FeaturedImage image={featuredImage} />}
        </div>
      </div>
    </Layout>
  )
}
